<script setup lang="ts"></script>

<template>
    <svg
        width="71"
        height="67"
        viewBox="0 0 71 67"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_d_855_7691)">
            <path
                d="M39.9403 41.5995C38.0162 44.9347 33.2026 44.9347 31.2784 41.5995L17.4355 17.6051C15.5124 14.2717 17.9181 10.1064 21.7664 10.1064L49.4523 10.1064C53.3006 10.1064 55.7063 14.2717 53.7833 17.6051L39.9403 41.5995Z"
                fill="#50A0EB"
            />
            <path
                d="M39.0741 41.0998C37.5348 43.7679 33.6839 43.7679 32.1446 41.0998L18.3017 17.1053C16.7632 14.4387 18.6878 11.1064 21.7664 11.1064L49.4523 11.1064C52.531 11.1064 54.4555 14.4387 52.9171 17.1053L39.0741 41.0998Z"
                stroke="url(#paint0_linear_855_7691)"
                stroke-width="2"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_855_7691"
                x="0.757812"
                y="0.106445"
                width="69.7031"
                height="65.9946"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="8" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.113725 0 0 0 0 0.12549 0 0 0 0 0.160784 0 0 0 0.55 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_855_7691"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_855_7691"
                    result="shape"
                />
            </filter>
            <linearGradient
                id="paint0_linear_855_7691"
                x1="35.6094"
                y1="49.1064"
                x2="35.6094"
                y2="10.1064"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#82C3FF" />
                <stop offset="1" stop-color="#82C3FF" stop-opacity="0" />
            </linearGradient>
        </defs>
    </svg>
</template>
